import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { MdPhone, MdMailOutline, MdOutlineLocationOn } from 'react-icons/md';
import SpinnerLoader from '../Utils/UI/SpinnerLoader';

import './styles.scss';
import logo from '../HeaderComponent/logo-small.png';

const Footer = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const value1 = 'M0,0c227,0,1717,0,1920,0c0,45,0,120,0,120C1186.579,33,421,206,0,114C0,84,0,0,0,0z';

  return (
    <div className="footer">
      {/* CONTENT */}
      <div className="footer-content">
        {/* LOGO + CONTACTS */}
        <div className="footer-content--left">
          <div className="footer-content--left_logo">
            {!imgLoaded && <SpinnerLoader />}
            <img src={logo} alt="formacoach logo" onLoad={() => setImgLoaded(true)} />
          </div>
          <ul className="footer-content--left_contacts">
            <li className="footer-content--left_contacts-item">
              <MdOutlineLocationOn />
              26, rue de l'étoile, 75017 Paris
            </li>
            <li className="footer-content--left_contacts-item">
              <MdPhone />
              +336 03 59 83 46 / +337 68 36 72 02
            </li>
            <li className="footer-content--left_contacts-item">
              <MdMailOutline />
              contact@formacoachdigital.com
            </li>
          </ul>
        </div>
        <div className="footer-content--right">
          <Link to={'/mentions-legales'}>Mentions légales</Link>
          <span>Copyright Forma'Coach {dayjs().get('year')}</span>
        </div>
      </div>
      {/* WAVE BACKGROUND */}
      <div className="footer-background">
        <svg
          className="footer-background--wave"
          xmlns="http://www.w3.org/2000/svg"
          // If screen smaller than 1281 we pull up the wave
          viewBox={`${window.innerWidth < 1281 ? '0 -60 1920 210' : '0 0 1920 150'}`}
          preserveAspectRatio="none"
          x="0"
          y={`-${window.innerHeight}`}
        >
          <path fill="#FFFFFF" className="footer-wave--path" d={`${value1}`} />
        </svg>
        <div className="footer-background--rectangle" />
        {/* {window.innerWidth < 1281 && } */}
      </div>
    </div>
  );
};

export default Footer;
